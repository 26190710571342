.notificationWrapper {
    background: #fdfdfd;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
    /* box-shadow: 0px 0px 0px rgb(0 0 0 / 20%); */
    filter: drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.039));
    border-radius: 0.5rem;
    border: 1px solid #F2F2F2;
}

.notificationImageIcon {
    flex-shrink: 0;
}

.notificationImageIcon img {
    max-width: 100%;
    width: 50px;
    aspect-ratio: 1/1;
}

.notificationText {
    font-weight: 600;
    color: #848282;
    margin-bottom: 0.5rem;
}

.dateTime {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    color: var(--primaryColor);
}


.customButton2{
    background: none;
    border: none;
    color: #77838F;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    text-decoration: underline;
    cursor: pointer;
}
.unread .customButton2{
    background: none;
    border: none;
    color: #7A7A7A;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    text-decoration: underline;
}

.unread{
    background: rgba(0, 114, 207, 0.25);
border: 1px solid rgba(0, 114, 207, 0.5);
}