.modal-header {
    border: none;
}

.modal-body {
    /* text-align: center; */
    padding: 20px 4rem;
}

.update-pop-txt {
    font-family: 'Aventa';
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #436b77;
}

.aa .input-group-text{
    display: none;
}